<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<!-- <div id="app" class="custom" oncontextmenu="return false">
		<component :is="layout">
			<router-view />
		</component>
	</div> -->
	<div id="app" class="custom">
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
export default ({
	name: "App",
	computed: {
		// Sets components name based on current route's specified layout, defaults to
		// <layout-default></layout-default> component.
		layout() {
			return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
		}
	},
	mounted() {
		// Prevents right click on the app.
		// document.addEventListener("contextmenu", this.preventRightClick);
	},
	methods: {
		// Prevents right click on the app.
		// preventRightClick(e) {
		// 	e.preventDefault();
		// }
	},
})

</script>

<style lang="scss">
.custom {
	// Sets the background color of the app.
	background-color: #212121;
}
</style>